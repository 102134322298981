@media screen and (max-width: 1024px) {
  .sidemenu {
    height: 100vh; /* 100% Full-height */
    width: $menu-width; /* 0 width - change this with JavaScript */
    margin-left: -$menu-width;
    position: fixed; /* Stay in place */
    z-index: 999999; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    padding: 0;
    box-shadow: $navbar-vertical-box-shadow;

    &.opened {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .sidemenu {
    height: 100vh; /* 100% Full-height */
    width: $menu-width; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 999999; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    padding: 0;
    box-shadow: $navbar-vertical-box-shadow;

    &.opened {
      width: $menu-opened-width;
    }
  }
}

.collapsed-invisible {
  visibility: hidden;
}

.sidemenu {
  &.opened {
    * > .collapsed-invisible {
      visibility: visible;
    }
  }
}
