//
// Chart
//

.chart {
    position: relative;
    height: $chart-height;
}


// Size variations

.chart-sm {
    height: $chart-height-sm;
}


// Legend

.chart-legend {
    display: flex;
    justify-content: center;
    margin-top: $chart-legend-margin-top;
    font-size: $chart-legend-font-size;
    text-align: center;
    color: $chart-legend-color;
}

.chart-legend-item {
    display: inline-flex;
    align-items: center;

    + .chart-legend-item {
        margin-left: 1rem;
    }
}

.chart-legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.375rem;
    border-radius: 50%;
}


// Tooltip

#chart-tooltip {
    z-index: 0;

    .arrow {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateX(-.5rem);
    }
}


// Chart info overlay

.chart-info-overlay {
  position: absolute;
  top: 0;
  left: 5%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

// Donut chart css

.donut-ring {
  stroke: $gray-300;
}

.donut-segment {
  transform-origin: center;
  stroke: $default;
}

.donut-segment-primary {
  stroke: $primary;
  animation: donut1 3s;
}

.donut-segment-secondary {
  stroke: $gray-500;
  animation: donut1 3s;
}

.donut-segment-success {
  stroke: $success;
  animation: donut1 3s;
}

.donut-segment-info {
  stroke: $info;
  animation: donut1 3s;
}

.donut-segment-warning {
  stroke: $warning;
  animation: donut2 3s;
}

.donut-segment-danger {
  stroke: $danger;
  animation: donut3 3s;
}

.donut-text {
  font-family: $font-family-base;
  fill: $default;
}

.donut-text-primary {
  fill: $primary;
}

.donut-text-secondary {
  fill: $gray-500;
}

.donut-text-success {
  fill: $success;
}

.donut-text-info {
  fill: $info;
}

.donut-text-warning {
  fill: $warning;
}

.donut-text-danger {
  fill: $danger;
}

.donut-percent {
  font-size: 0.5em;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: bold;
}

.donut-data {
  font-size: 0.12em;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
  color: $gray-600;
  fill: $gray-600;
  animation: donutfadelong 1s;
}
