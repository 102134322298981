/* You can add global styles to this file, and also import other style files */
// CK-Editor Variables

:root {
  --ck-border-radius: 0.5em !important;
  --ck-color-focus-border: rgba(50,151,211,.35) !important;
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0) !important;

  --ck-font-size-base: 0.75rem !important;
  --ck-font-face: Open Sans, sans-serif !important;

  --ck-font-size-tiny: 0.75em !important;
  --ck-font-size-small: 0.875em !important;
  --ck-font-size-normal: 1em !important;
  --ck-font-size-big: 1.25em !important;
  --ck-font-size-large: 1.5em !important;
}

.ck {
  &:hover {
    border-color: rgba(50, 151, 211, .15) !important;
    border-width: 1px !important;
    background-color: white !important;
  }
  &:focus {
    border-color: rgba(50,151,211,.35) !important;
    background-color: white !important;
  }
}


.ck-border {
  > .ck {
    background-color: white;
    border-color: rgba(50, 151, 211, .15) !important;
    border-width: 1px !important;
    box-shadow: none;

    &:hover {
      border-color: rgba(50, 151, 211, .15) !important;
      border-width: 1px !important;
      background-color: white !important;
    }

    &:focus {
      border-color: rgba(50, 151, 211, .35) !important;
      background-color: white !important;
    }
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-text {
  cursor: text;
}

.btn-min-width {
  min-width: 80px;
}

.dropdown-menu {
  position: absolute !important;
  z-index: 9999999 !important;
  top: 1em !important;
  right: 1em !important;
  left: auto !important;
}

.dropdown-left {
  position: absolute !important;
  z-index: 9999999 !important;
  right: auto !important;
  left: 1em !important;
}

.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-overlay-container, .cdk-overlay-pane { z-index: 9999 !important; }

// Fixed Sidenav Header
/* Style the header */
.stick-header {
  padding: 0;
  margin: 0;
  background: transparent;
  transition: background 0.3s;

  > .stick-invisible {
    display: block;
  }
  > .stick-visible {
    display: none;
  }
  > .stick-resize {
    min-height: 30px;
  }
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  background: #f7fafc;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: 0 0 0.5rem 0 rgba(136, 152, 170, .15);
  transition: background 0.3s;
  z-index: 9999;
  padding: 0.5rem;
  margin: 0;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #f7fafc;
  border-top: 1px solid #e3e3e3;
  box-shadow: 0 0.5rem 0 0 rgba(136, 152, 170, .15);
  z-index: 9999;
  padding: 0.5rem;
  margin: 0;
}

.sidenav-content {
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  right: 0;
  background: #f7fafc;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: 0 0 0.5rem 0 rgba(136, 152, 170, .15);
  color: #f1f1f1;
  transition: background 0.3s;
  z-index: 9999;
  padding: 0.5rem;
  margin: 0;

  > .stick-invisible {
    display: none;
  }
  > .stick-visible {
    display: block;
  }
}

/* way the hell off screen */
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.default-edit-container {
  @media screen and (min-width: 1024px) {
    min-width: 80em;
    max-width: 80em;
  }
  min-height: 100%;
}

.show-to-print {
  display: none;
}

@media print {
  * { overflow: visible !important; }
  body {
    visibility: hidden;

    * > .mat-sidenav-fixed { position: absolute; }

    * > .main-content { overflow: hidden !important; }
  }
  #section-to-print {
    display: block;
    page-break-before: always;
    background-color: white;
    height: auto;
    width: 100%;
    //position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;

    * > .hide-to-print {
      display: none !important;
    }
    * > .show-to-print {
      display: inline !important;
    }
  }
}
