//
// Input group
//

.input-group {
  border-style: solid;
  border-color: $input-border-color;
  border-width: $input-border-width;
  background: $input-bg;
  box-shadow: $input-box-shadow;
  border-radius: $input-border-radius;
  transition: $transition-base;

  .form-control,
  .input-group-text {
    border: 0;
    box-shadow: none;
  }

  &:hover {
    border-style: solid;
    border-color: $input-hover-border-color;
    border-width: $input-hover-border-width;
    background-color: $input-hover-bg;
    border-radius: $input-border-radius;
    box-shadow: none;

  }

  &:focus {
    border-style: solid;
    border-color: $input-focus-border-color;
    border-width: $input-focus-border-width;
    background-color: $input-focus-bg;
    border-radius: $input-border-radius;
    box-shadow: none;
  }

}

.input-group-disabled {
  background-color: $input-disabled-bg !important;
}

.input-group-text {
  transition: $input-transition;
}

.input-group-border {
  background: $input-hover-bg;
  border-style: solid;
  border-color: $input-hover-border-color !important;
  border-width: $input-hover-border-width !important;
  box-shadow: $input-box-shadow;

  .form-control,
  .input-group-text {
    border: 0;
    box-shadow: none;
  }

  &:hover {
    border-color: $input-hover-border-color;
  }

  &:focus {
    border-color: $input-focus-border-color;
  }
}

// Alternative input groups related to .form-control-alternative


.input-group-alternative {
  background: $input-bg-alternative;
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow .15s ease;

  .form-control,
  .input-group-text {
    border: 0;
    box-shadow: none;
  }

  &:hover {
    box-shadow: $input-focus-alternative-box-shadow;
  }

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

.focused {
  &.input-group {
    border-color: $input-focus-border-color !important;
  }
  &.input-group-alternative {
    box-shadow: $input-focus-alternative-box-shadow !important;
  }
  &.input-group-border {
    border-color: $input-focus-border-color !important;
  }
}


.hovered {
  .input-group {
    box-shadow: $input-focus-box-shadow;
  }

  //.input-group-append > .input-group-text {
  //  color: $input-group-addon-hover-color;
  //  background-color: $input-group-addon-hover-bg;
  //  border-color: $input-group-addon-hover-border-color;
  //  border-width: $input-group-addon-hover-border-width;
  //  border-left: 0;
  //  padding-left: 0;
  //}

  .input-group-text {
    color: $input-group-addon-hover-color;
    background-color: $input-group-addon-hover-bg;
    border-color: $input-group-addon-hover-border-color;
    border-width: $input-group-addon-hover-border-width;
  }

  .form-control {
    border-color: $input-group-addon-hover-border-color;
  }
}

.invalid {
  border-color: $form-feedback-invalid-color !important;

  .input-group {
    box-shadow: $input-focus-box-shadow;
  }

  .input-group-text {
    color: $input-group-addon-focus-color;
    background-color: $input-group-addon-focus-bg;
    border-color: $form-feedback-invalid-color !important;
    border-width: $input-group-addon-focus-border-width;
  }

  .form-control {
    border-color: $form-feedback-invalid-color !important;

    &:not(:first-child) {
      border-left: 0;
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 0;
      padding-right: 0;
    }
  }
}

// .focus class is applied dinamycally from theme.js
.focused {
  .input-group {
    box-shadow: $input-focus-box-shadow;
  }

  .input-group-text {
    color: $input-group-addon-focus-color;
    background-color: $input-group-addon-focus-bg;
    border-color: $input-group-addon-focus-border-color;
    border-width: $input-group-addon-focus-border-width;
  }

  .form-control {
    border-color: $input-group-addon-focus-border-color;
  }
}
