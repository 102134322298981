//
// Icon buttons
//

// General styles

.btn {
   position: relative;
   text-transform: $btn-text-transform;
   transition: $transition-base;
   letter-spacing: $btn-letter-spacing;
   font-size: $input-btn-font-size;
   will-change: transform;

   min-width: $input-btn-font-size * 2;
   min-height: $input-btn-font-size * 2;

   &:hover {
      @include box-shadow($btn-hover-box-shadow);
      transform: translateY($btn-hover-translate-y);
   }

   &:not(:last-child) {
      margin-right: .5rem;
   }


   // Icons

   i:not(:first-child),
   svg:not(:first-child) {
      margin-left: .5rem;
   }

   i:not(:last-child),
   svg:not(:last-child) {
      margin-right: .5rem;
   }
}

.btn-rounded {
  border-radius: $input-btn-font-size-sm * 2;
  max-width: $input-btn-font-size-sm * 2;
  max-height: $input-btn-font-size-sm * 2;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 1024px) {
    z-index: 9;
  }
}

.btn-rounded .btn-lg {
  border-radius: $input-btn-font-size-lg * 2;
  font-size: $input-btn-font-size-lg;
  max-width: $input-btn-font-size-lg * 2;
  max-height: $input-btn-font-size-lg * 2;
}

.btn-flat {
  border-style: none;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    color: $primary !important;
    background-color: $gray-500 !important;
  }
}

.btn-flat.disabled {
  color: $gray-400 !important;
  &:hover {
    color: $primary !important;
    background-color: $gray-400 !important;
  }
}


// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
   .btn {
      margin-right: 0;
      transform: translateY(0);
   }
}


// Size variations

.btn-sm {
   font-size: $input-btn-font-size-sm;
   min-width: $input-btn-font-size-sm * 2;
   min-height: $input-btn-font-size-sm * 2;
}

.btn-lg {
   font-size: $input-btn-font-size-lg;
   min-width: $input-btn-font-size-lg * 2;
   min-height: $input-btn-font-size-lg * 2;
}


// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
   border-width: 1px;
}

.btn-outline-secondary:not(.lighten) {
  color: $gray-800;
}

.btn-secondary {
  color: darken(theme-color("secondary"), 50%);
}

.lighten {
  border-color: $white;
  color: lighten(theme-color("secondary"), 50%);
}

.btn-inner--icon {
   i:not(.fa) {
      position: relative;
      top: 2px;
   }
}

.btn-link {
   font-weight: $btn-font-weight;
   box-shadow: none;

   &:hover {
      box-shadow: none;
      transform: none;
   }
}

.btn-neutral {
   color: theme-color("primary");
}


// Toggle Buttons
// TODO: Ajustar as cores de acordo com as váriáveis do tema

.toggle-btn-group {
  display: inline-block;
  padding: 4px;
  border-radius: 24px 24px 24px 24px;
  background: rgba($secondary, 0.5);

  &.darken {
    background: rgba($default, 0.5);
    //background: darken(theme-color("secondary"), 50%);
  }

  &.mobile-bottom {
    @media screen and (max-width: 1024px) {
      position: fixed;
      bottom: 5px;
      left: 5px;
      margin: 0 auto;
      z-index: 99;
      background: rgba($default, 0.8);

      >.toggle-btn {
        display: block;
        z-index: 999;
        color: white;
        &.selected {
          color: $default;
        }
      }
    }
  }
}

.toggle-btn-group .toggle-btn {
  float: left;
  padding: 0.2rem 1rem 0.2rem 1rem;
  margin: 0;
  height: 32px;
  min-width: 65px;
  background: transparent;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: rgba(30, 30, 30, .8);
  border: none;
}

.toggle-btn-group .toggle-btn:only-of-type {
  border-radius: 16px;
}


.toggle-btn-group > button:hover {
  color: $primary;
  transition: all 0.1s linear;
  cursor: pointer;
}

.darken > button:hover {
  color: $gray-300;
  transition: all 0.1s linear;
  cursor: pointer;
}

.toggle-btn-group > button:active {
  //background: #505050;
}

.toggle-btn-group > .selected {
  color: black;
  background: white;
  border-radius: 16px 16px 16px 16px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.toggle-btn.selected {
  color: black;
  background: white;
  border-radius: 16px 16px 16px 16px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
