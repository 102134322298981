//
// Calendar
//

.calendar-event {
  display: flex;
  flex-direction: column;
  border-left: 3px solid $default;
  background-color: rgba(#d0d5db, 0.8);
  padding: 0.25em;
  height: 100%;
  width: 100%;

  &.primary {
    border-left: 3px solid $primary;
    background-color: rgba(#dfe3fa, 0.8);
  }

  &.secondary {
    border-left: 3px solid $secondary;
    background-color: rgba(#fdfefe, 0.8);
  }

  &.success {
    border-left: 3px solid $green;
    background-color: rgba(#d0f5e7, 0.8);
  }

  &.info {
    border-left: 3px solid $info;
    background-color: rgba(#d0f5fc, 0.8);
  }

  &.warning {
    border-left: 3px solid $warning;
    background-color: rgba(#fce0d9, 0.8);
  }

  &.danger {
    border-left: 3px solid $danger;
    background-color: rgba(#fcd6df, 0.8)
  }

  > label {
    font-size: 0.625rem;
  }
}

.navigator {
  margin: 5px 10px 5px 10px;
  padding-left: 10px;
  border-left: 1px solid #ccc;

  > daypilot-navigator {
    display: none;
  }
}

.calendar-navigator {
  width: 16em;
}

.calendar-navigator-cell {
  width: 3em;
  height: 3em;
  font-size: smaller;
  vertical-align:top;
  * > i {
    font-size: 0.3rem;
    margin-left: 0.05rem;
    margin-right: 0.05rem;
  }
}

.calendar-navigator-cell-selected {
  background-color: #ced4da;
  border-radius: 12px;
}

.calendar-navigator-row-selected {
  border-radius: 0px;
  background-color: #ced4da;

  > :first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  > :last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
