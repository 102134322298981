//
// Alert
//

.alert {
  font-size: $font-size-sm;
  &>a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $gray-300;
    }
  }
}

// Alert heading

.alert-heading {
    font-weight: $font-weight-bold;
    font-size: $h4-font-size;
    margin-top: .15rem;
}


// Alert icon
.alert-icon {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    display: inline-block;
    vertical-align: middle;

    i.ni {
        position: relative;
        top: 1px;
    }
}


// Alert text next to an alert icon
.alert-text {
    display: inline-block;
    vertical-align: middle;
}


// Alert links

[class*="alert-"] {
    .alert-link {
        color: $white;
        border-bottom: 1px dotted rgba($white, .5);
    }
}
