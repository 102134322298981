//
// Separator
//

@import "../core/separators/separator";

.border-top-separator {
  border-top: $border-width $card-border-color solid ;
  border-radius: 0;
}
