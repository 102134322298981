//
// Card stats
//

.card-stats {
    .card-body {
        padding: 1rem 1.5rem;
    }

    .card-status-bullet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}

.card-delayed-border {
  @media screen and (min-width: 1024px) {
    border-right: 1px solid white !important;
  }

  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid white !important;
    margin-bottom: 0.5em;
  }
}

.card-delayed-border-secondary {
  @media screen and (min-width: 1024px) {
    border-right: 1px solid $gray-300 !important;
  }

  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid $gray-300 !important;
    margin-bottom: 0.5em;
  }
}
