//
// Avatar
//

// General styles

.avatar {
	color: $white;
	background-color: $gray-500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	border-radius: 50%;
	height: 48px;
	width: 48px;

    img {
      height: 100%;
      width: 100%;
    	border-radius: 50%;
    }

    + .avatar-content {
    	display: inline-block;
    	margin-left: .75rem;
    }
}


// Avatar size variations

.avatar-xl {
  width: 180px;
  height: 180px;
  font-size: $font-size-xl;
}

.avatar-lg {
	width: 58px !important;
	height: 58px !important;
	font-size: $font-size-lg;
}

.avatar-sm {
	width: 36px !important;
	height: 36px !important;
	font-size: $font-size-sm;
}

.avatar-xs {
  width: 24px !important;
  height: 24px !important;
  font-size: $font-size-xs;
}

.avatar-remove-btn {
  position: relative;
  cursor: pointer;
  top: 110px;
  left: 45px;
  z-index: 99;
}

// Select avatar
.select-avatar-sm {
  width: 32px;
  height: 32px;
  font-size: $font-size-sm;
  color: $light;
  background-color: #f6f9fc;
  border-style: dashed !important;
  border-width: thin !important;
  border-color: $light !important;
  z-index: 1 !important;

  &:hover {
    z-index: 3 !important;
    color: $primary;
  }
}

.select-avatar-xs {
  width: 24px;
  height: 24px;
  font-size: $font-size-xs;
  color: $light;
  background-color: #f6f9fc;
  border-style: dashed !important;
  border-width: thin !important;
  border-color: $light !important;
  z-index: 1 !important;

  &:hover {
    z-index: 3 !important;
    color: $primary;
  }
}
