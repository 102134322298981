//
// Forms
//


// Labels

.form-control-label {
  color: $gray-600;
  font-size: $font-size-xs;
  padding-left: 1em;
}


// Text inputs

.form-control {
  font-size: $input-btn-font-size;
  color: $input-color;
  border-color: $input-border-color;
  border-width: $input-border-width;
  box-shadow: $input-box-shadow;
  background: $input-bg;

  &:hover {
    color: $input-hover-color;
    border-color: $input-hover-border-color;
    border-width: $input-hover-border-width;
    box-shadow: $input-hover-box-shadow;
    background-color: $input-hover-bg;

    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }

  &:focus {
    color: $input-focus-color;
    border-color: $input-focus-border-color;
    border-width: $input-focus-border-width;
    box-shadow: $input-focus-box-shadow;
    background-color: $input-focus-bg;

    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }
}

// Textarea

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}


// Form input variations

// Muted input

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-focus-muted-bg;
    }
}

.form-control-border {
  background-color: $input-hover-bg;
  border-color: $input-hover-border-color;
  border-width: $input-hover-border-width;
  box-shadow: none;
}


// Alternative input

.form-control-alternative {
  background: $input-bg-alternative;
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow .15s ease;

  &:hover {
    box-shadow: $input-focus-alternative-box-shadow;
  }

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

select option {
  font-family: $font-family-base;
  font-size: $font-size-base;
}


// Size variations: Fixes to the bootstrap defaults

.form-group-sm {
  margin-bottom: 0;
}

.form-control-sm {
  font-size: $font-size-sm;
}

.form-control-lg {
    font-size: $font-size-base;
}


// Specific inputs type
.oab-number {
  background: url(/assets/img/icons/oab-icon.png) no-repeat scroll 6px 6px;
  background-size: 28px;
  padding-left: 32px;
  padding-right: 32px;
}

.cpf-number {
  background: url(/assets/img/icons/cpf-icon.png) no-repeat scroll 6px 6px;
  background-size: 28px;
  padding-left: 32px;
  padding-right: 32px;
}

.cnpj-number {
  background: url(/assets/img/icons/cnpj-icon.png) no-repeat scroll 6px 6px;
  background-size: 28px;
  padding-left: 32px;
  padding-right: 32px;
}

.doc-number {
  background: url(/assets/img/icons/doc-icon.png) no-repeat scroll 6px 6px;
  background-size: 28px;
  padding-left: 32px;
  padding-right: 32px;
}
