//
// Circle badge
//


// General styles

.badge-circle {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: .875rem;

    &.badge-lg {
      width: $input-btn-font-size-lg * 2;
      height: $input-btn-font-size-lg * 2;
      font-size: $input-btn-font-size-lg;
    }

    &.badge-sm {
      width: $input-btn-font-size-sm * 2;
      height: $input-btn-font-size-sm * 2;
      font-size: $input-btn-font-size-sm;
    }
}
