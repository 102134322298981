/*!

=========================================================
* Argon Dashboard Angular - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Angular theme

@import '~@angular/material/theming';
@include mat-core();

$argon-app-primary: mat-palette($mat-indigo, 400);
$argon-app-accent:  mat-palette($mat-grey, 100);
$argon-app-warn:    mat-palette($mat-orange, 900);

$argon-app-theme: mat-light-theme((
  color: (
    primary: $argon-app-primary,
    accent: $argon-app-accent,
    warn: $argon-app-warn,
  )
));

@include angular-material-theme($argon-app-theme);

// Bootstrap (4.3.0) components

// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";

// Angular Differences
@import "angular-differences/angular-differences";

//$argon-app-theme: mat-light-theme((
//  color: $theme-colors
//));
//
//@include angular-material-theme($argon-app-theme);
